.ant-layout-header {
  position: relative;
  z-index: 10;
  max-width: 100%;
  background: #fff;
  box-shadow: 0 2px 8px #f0f1f2;
}
.ant-layout-content {
  padding: 16px 28px 28px 28px;
  margin: 0px;
  background: #fff;
  min-height: calc(100vh - 112px);
}
.ant-layout-footer {
  padding: 12px 24px;
  border-top: solid 1px rgb(235, 237, 240);
  background: #fff;
}
.ant-layout-header .logo {
  margin: 0px;
  height: 100%;
  display: flex;
  align-items: center;
}
.ant-layout-header .logo img {
  height: 48px;
}
.ant-page-header {
  border-bottom: solid 1px rgb(235, 237, 240);
  padding: 0px 0px 8px 0px;
  margin-bottom: 8px;
}
.ant-page-header-heading-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.ant-list-items .ant-list-item .ant-avatar {
  font-size: 24px;
  line-height: 34px;
  border-radius: 4px;
}
.ant-list-items .ant-list-item:nth-child(4n + 1) .ant-avatar {
  background: #e9f3fc;
  color: #0d2943;
}
.ant-list-items .ant-list-item:nth-child(4n + 2) .ant-avatar {
  background: #ecf4ee;
  color: #083d15;
}
.ant-list-items .ant-list-item:nth-child(4n + 3) .ant-avatar {
  background: #fdf1dd;
  color: #3a2809;
}
.ant-list-items .ant-list-item:nth-child(4n + 4) .ant-avatar {
  background: #f1f1ff;
  color: #111136;
}
.ant-layout {
  background: #fff;
}
.container {
  width: 1140px;
  padding: 0px 15px;
}
.container-s {
  width: 1140px;
  padding: 0px 15px;
  margin: 0px auto;
}
.dashboard-menu {
  display: flex;
  flex-wrap: wrap;
  border: 0px none;
}
.dashboard-menu li {
  display: flex;
  flex: 0 0 calc(20% - 16px);
  border: solid 1px #f0f0f0;
  margin-right: 16px;
  flex-wrap: wrap;
  padding: 24px 24px 24px 24px !important;
  height: auto !important;
}
.dashboard-menu li span svg {
  font-size: 34px;
  color: #333;
}
.ant-menu-vertical {
  font-size: 10px;
}
.ant-menu-vertical > .ant-menu-item {
  height: auto !important;
  line-height: normal !important;
  padding: 8px 8px !important;
  text-align: center;
}
.ant-menu-vertical > .ant-menu-item > .ant-menu-title-content {
  opacity: 0.8 !important;
  display: block !important;
  padding: 0px !important;
  margin: 0px !important;
}

.ant-menu-vertical > .ant-menu-item > .anticon {
  height: auto !important;
  line-height: normal !important;
}
.ant-table-thead > tr > th {
  font-weight: 600;
}
.ant-tabs-top > .ant-tabs-nav {
  margin: 0px !important;
}
.ant-tabs-content.ant-tabs-content-top {
  border: 1px solid #f0f0f0;
  border-top: none;
  padding: 16px 24px;
}
.d-view b {
  margin-top: 16px;
  display: block;
}
.d-view span {
  display: block;
  padding-left: 16px;
}
.overview-view {
  white-space: pre-wrap;
}

/* Public Pages Menu */
.public-header {
  display: flex;
  padding: 0px;
  margin: 0px;
}
.public-header-menu {
  position: absolute;
  right: 20px;
  top: 0px;
}
.public-header-menu a {
  font-size: 0.9rem;
  text-decoration: none;
  color: #333;
  margin: 0px 4px;
  padding: 0px 16px;
  border-radius: 6px;
  line-height: 34px;
  display: inline-block;
}
.public-header-menu a:hover {
  color: #1677ff;
}
.public-header-menu a:last-child {
  background: #1677ff;
  color: #fff;
}

/* Signin and Signup */
.signin-box {
  position: absolute;
  border: solid 1px #f0f0f0;
  margin: auto;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  border-radius: 8px;
  box-shadow: 0 2px 8px #f0f1f2;
  width: fit-content;
  height: fit-content;
  min-width: 422px;
}
.signin-box-title {
  border-bottom: solid 1px #f0f0f0;
  margin: 0px !important;
  padding: 12px 24px !important;
}
.signin-form {
  padding: 24px;
  margin: 0px;
}

.ant-form .ant-form-item.btn-row button {
  margin-right: 16px;
}

.reset-icon-btn {
  padding: 0px;
  margin: 0px;
  height: auto;
  width: auto;
  border: 0px none;
  background: none;
  box-shadow: none;
  display: flex;
  align-items: center;
}

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  * {
    font-family: Arial, Helvetica, sans-serif !important;
  }
  img {
    display: table !important;
    break-inside: avoid !important;
  }
  .iti-dtl-print {
    box-shadow: none !important;
    max-width: 100% !important;
  }
  .iti-dtl-banner {
    min-height: auto !important;
  }
  .iti-dtl-btns {
    display: none !important;
  }
  .ant-timeline {
    padding: 42px 0px 0px 0px !important;
  }
  .iti-dtl-item-row {
    display: table;
    float: left;
    break-inside: avoid !important;
  }
  .iti-dtl-item-img {
    width: 35% !important;
    float: left;
  }
  .iti-dtl-item-dtl {
    width: 50% !important;
    float: left;
  }
  .iti-dtl-item-price {
    width: 15% !important;
    float: left;
  }
  .iti-dtl-item-desc {
    width: 100% !important;
    float: left;
  }
}

@media (max-width: 575px) {
  .ant-form .ant-form-item .ant-form-item-label {
    padding-bottom: 0px;
  }
  .ant-form .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form .ant-form-item.btn-row {
    padding-top: 12px;
  }
  .ant-layout-sider {
    display: none;
  }
  .leads-summary {
    display: none;
  }
  .ant-page-header .ant-page-header-heading-left {
    margin-bottom: 8px;
  }
  .ant-list-item-action {
    margin: 8px 0px 0px 0px !important;
  }
  .lead-col-2 {
    margin-top: 16px;
  }
  .lead-avatar {
    display: none !important;
  }
  .ant-list-item {
    border: solid 1px #f0f0f0 !important;
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 16px 12px 12px 12px !important;
    box-shadow: 0 2px 8px #f0f1f2;
    text-align: center;
    justify-content: center !important;
  }
  .public-header {
    padding: 0px 4%;
  }
  .signin-box {
    left: 4%;
    right: 4%;
    min-width: 92%;
  }
}

.invoice-html html,
.invoice-html body {
  padding: 0px;
  margin: 0px;
  width: 100%;
}

/* Website Admin */
.website-admin .ant-tabs-content.ant-tabs-content-top {
  border: 0px none;
  padding: 16px 0px;
}
.deal-add .ant-tabs-content.ant-tabs-content-top {
  border: 0px none;
  padding: 16px 0px 0px 0px;
}
.web-settings h4 {
  font-size: 1rem;
  font-weight: 600;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  padding: 24px 0px 0px 0px;
  margin: 0px 0px 16px 0px;
}
.web-settings .web-settings-label {
  padding: 0px;
  margin: 6px 0px 0px 16px;
  font-weight: 600;
}

.in-100 {
  width: 100%;
}

.ant-table .ant-table-thead .ant-table-cell {
  white-space: nowrap;
}

.page-loader {
  position: fixed;
  top: 0px;
  left: 0px;
  background: #fff;
  width: 100%;
  z-index: 10000;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-bg-blue {
  background: linear-gradient(to left, #fff 0%, #fff 30%, #cfe8ff 100%);
}
.row-bg-green {
  background: linear-gradient(to left, #fff 0%, #fff 30%, #cfffd7 100%);
}
.row-bg-purple {
  background: linear-gradient(to left, #fff 0%, #fff 30%, #f3ecff 100%);
}
.row-bg-gray {
  background: linear-gradient(to left, #fff 0%, #fff 30%, #fff4f4 100%);
}
.row-bg-red {
  background: linear-gradient(to left, #fff 0%, #fff 30%, #ffcfcf 100%);
}
